import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { Mdx } from 'common/types'
import Layout from 'components/layout'
import SEO from 'components/seo'
import { Title, Published, Content } from './styles'

type Props = {
  data: {
    site: {
      siteMetadata: {
        title: string
      }
    }
    mdx: Mdx
    coverPicture: {
      publicURL: string
    }
  }
}

const BlogPostTemplate = ({ data }: Props) => {
  const isStandalone = data.mdx.frontmatter.tags?.indexOf('standalone') !== -1

  return (
    <Layout>
      <SEO
        title={data.mdx.frontmatter.title}
        description={data.mdx.frontmatter.description || data.mdx.excerpt}
        post={data.mdx}
        thumbnailPath={data.coverPicture?.publicURL}
      />
      <Title>{data.mdx.frontmatter.title}</Title>
      {!isStandalone && (
        <Published>Published on {data.mdx.frontmatter.date}</Published>
      )}
      <Content>
        <MDXRenderer>{data.mdx.body}</MDXRenderer>
      </Content>
      {/* {!isStandalone && (
        <Subscribe>
          <p>
            Want to become a better developer?
            <br />
            Subscribe to the newsletter:
          </p>
          <SubscribeForm list="common" autofocus={false} />
        </Subscribe>
      )} */}
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $thumbnailRegex: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
      }
      fields {
        slug
      }
    }
    coverPicture: file(absolutePath: { regex: $thumbnailRegex }) {
      publicURL
    }
  }
`
