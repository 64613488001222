import styled, { css } from 'styled-components'
import { hoverEffect } from 'styles/mixins'
import mq from 'styles/mq'
import theme from 'styles/theme'

const wideStyles = () =>
  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].reduce((acc, val) => {
    return (acc += `
      .grid-xl-${val} {
        img {
          &:nth-child(${val + 1}) {
            grid-column: span 2;
          }
        }
      }

      .grid-xxl-${val} {
        img {
          &:nth-child(${val + 1}) {
            grid-column: span 3;
          }
        }
      }
    `)
  }, '')

const gridStyles = css`
  .grid {
    display: grid;
    gap: 0.3rem;
    margin: 5rem 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .grid-3 {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .grid-2 {
    grid-template-columns: 1fr 1fr;
  }

  ${wideStyles()}
`

export const Content = styled.div`
  ${gridStyles};

  a:not(.anchor) {
    ${hoverEffect};
  }

  .gatsby-resp-image-figure {
    margin: 0;

    ${mq.medium} {
      margin: 2rem 0 3rem;
    }

    .gatsby-resp-image-figcaption {
      text-align: center;
      font-size: 1.4rem;
      color: ${theme.color.muted};
      padding: 1rem;

      a {
        color: ${theme.color.muted};
        text-decoration: underline;
      }
    }
  }

  .gatsby-highlight {
    overflow: scroll;
    margin: 2rem -2rem;
    border-top: 0.1rem solid ${theme.color.border};
    border-bottom: 0.1rem solid ${theme.color.border};

    ${mq.medium} {
      margin: 2rem 0 3rem;
    }

    .line-numbers {
      padding: 0;
      overflow: initial;
    }
  }

  .gatsby-highlight-code-line {
    background-color: #fffbdd;
    display: block;
  }

  .gist {
    margin: 0 -2rem;

    .blob-code-inner {
      font-size: 1.4rem;
    }

    ${mq.medium} {
      margin: 4rem 0;
    }

    .gist-file {
      border: none;
    }

    .gist-meta {
      display: none;
    }

    .gist-data {
      border-top: 0.1rem solid ${theme.color.border};
      border-bottom: 0.1rem solid ${theme.color.border};
      padding: 1rem 0;

      ${mq.medium} {
        padding: 2rem 0;
      }
    }
  }

  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;

    ${mq.medium} {
      max-width: auto;
    }
  }
`

export const Subscribe = styled.div`
  text-align: center;
  border-top: 0.1rem solid ${theme.color.border};
  padding: 2rem 0 1rem;
  margin: 0;

  p {
    font-size: 2.1rem;
    font-weight: bold;
  }

  ${mq.medium} {
    padding: 5rem 0 3rem;
    margin: 5rem 0 0;
  }
`

export const Title = styled.h1`
  text-align: center;
  margin: 8rem 0 2rem;
`

export const Published = styled.small`
  text-align: center;
  font-style: italic;
  display: block;
`
